
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PrivacyPolicy",
  setup() {
    const storeX = useStore();
    const pageData = ref(null);

    const getCmsPage = async () => {
      settingsApp.setStoreToSession(storeX.state.storeCode).then(() => {
        const lang = storeX.state.storeCode.toUpperCase();
        const identifier = 'Privacy-Policy-' + lang + '.html';
        httpApp
          .sendPostRequest(settingsApp.getEndpointUrl("cmsPage"), {
            identifier: identifier,
          })
          .then((response: any) => {
            if (response.success) {
              pageData.value = response.page_data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    watch(
      () => storeX.state.storeCode,
      () => {
        getCmsPage()
      }
    );

    const ionViewWillEnter = () => {
      getCmsPage();
    };

    return {
      pageData,
      ionViewWillEnter,
    };
  },
  components: {
    IonPage,
    IonContent,
    MainHeader,
    MainFooter,
  },
});
